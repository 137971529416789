import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
import { FormControl, Label, IDropdownItem, Dropdown } from '@uitk/react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Range, DateRange } from 'react-date-range';
import { DateSelection } from 'shared/widgets/Banner/types';
import EnableFullScreenWidth from '../EnableFullScreenWidth';

const labelStyles = {
  color: '#1d1929',
  'font-size': '12px',
  'font-family': 'OptumSans',
  'margin-left': '-10px',
  'padding-top': '20px',
  'font-weight': '500',
  'margin-right': '20px',
};

const typographyStyles = {
  fontSize: '12px',
  fontFamily: 'OptumSans',
};

const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  .custom-date-range {
    width: 100%;
    .rdrDateDisplayWrapper {
      cursor: pointer;
    }
    .rdrDateInput {
      pointer-events: none;
    }
  }
  .custom-date-range.collapsed {
    .rdrMonthAndYearWrapper,
    .rdrMonths {
      display: none;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .gQmwFj {
    font-family: 'OptumSans';
    width: fit-content;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 150px;
  #banner-controlled-select {
    padding-left: 0.5rem;
  }
`;

const StyledText = styled(Typography)`
  font-family: 'OptumSans';
`;

const FullScreenWrapper = styled.div`
  margin: -20px 0 -10px -10px;
  & > div > label {
    font-size: 12px;
  }
`;

const browserListItems: IDropdownItem[] = [
  { id: '1', label: 'Chrome', value: 'Chrome' },
  { id: '2', label: 'FireFox', value: 'FireFox' },
  { id: '3', label: 'Safari', value: 'Safari' },
  { id: '4', label: 'Edge', value: 'Edge' },
];

export interface BannerGeneralProps<T> {
  browserCondition: boolean;
  showonAllpage: boolean;
  browserList: string;
  onChangeBrowserList: (e: string) => void;
  onChangeBrowserCondition: (e: boolean) => void;
  onChangeDateSelection?: (e: any) => void;
  dateSelectionRange?: DateSelection;
  handleFullScreen: (data: boolean) => void;
  fullScreenEnabled: boolean;
  handleWidgetPosition: (id: string, height: number, oldHeight: number) => void;
  widgetState: Widget<T>;
  removeWidget?: (widgetId: string) => void;
  currentPage: PageVersion;
}

export const BannerGeneralConfig: React.FC<BannerGeneralProps<any>> = (
  props: BannerGeneralProps<any>
) => {
  const {
    browserCondition,
    browserList,
    onChangeBrowserCondition,
    onChangeBrowserList,
    onChangeDateSelection,
    dateSelectionRange,
    handleFullScreen,
    fullScreenEnabled,
  } = props;
  const lovelyStyles = useLovelySwitchStyles();

  const getBrowserValue = () => {
    const browserDropdownValue = browserListItems.find(
      item => item.value === browserList
    );
    return browserDropdownValue ? browserDropdownValue : '';
  };

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = event => {
    (event.target.classList.contains('rdrDateDisplay') ||
      event.target.classList.contains('rdrDateDisplayWrapper')) &&
      setCollapsed(prev => !prev);
  };

  const onDateSelectionChange = (item: Range) => {
    if (item.selection) {
      const currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      const endDate = new Date(item.selection.endDate);
      if (currentDate !== endDate && currentDate > endDate) {
        props.handleWidgetPosition(
          props.widgetState.id,
          0,
          props.widgetState.config.height
        );
      }
    }

    setState([item.selection]);
    onChangeDateSelection(item.selection);
  };

  const onDropDownChange = (item: any) => {
    onChangeBrowserList(item.value);
  };

  const cx = (...classNames: Array<string | undefined | false>) =>
    classNames.filter(cn => !!cn).join(' ');

  const formatedDateRange = (dateRange: DateSelection) => {
    return [
      {
        startDate: new Date(dateRange.startDate),
        endDate: new Date(dateRange.endDate),
        key: 'selection',
      },
    ];
  };

  return (
    <>
      <FullScreenWrapper>
        <EnableFullScreenWidth
          handleEnableFullScreen={handleFullScreen}
          enableFullScreenWidth={fullScreenEnabled}
        />
      </FullScreenWrapper>
      <Label style={labelStyles}>Date Range</Label>
      <DateRangeWrapper onClick={toggleCollapse}>
        <DateRange
          data-test-id="date-range-select"
          className={cx('custom-date-range', collapsed && 'collapsed')}
          editableDateInputs={true}
          onChange={item => onDateSelectionChange(item)}
          moveRangeOnFirstSelection={false}
          ranges={
            dateSelectionRange ? formatedDateRange(dateSelectionRange) : state
          }
          minDate={new Date()}
        />
      </DateRangeWrapper>
      <Wrapper>
        <Label style={labelStyles}>Browser Conditions</Label>
        <FormControlLabel
          data-test-id="browser-condition-form"
          control={
            <Switch
              classes={lovelyStyles}
              checked={browserCondition}
              onChange={e => onChangeBrowserCondition(e.target.checked)}
              data-test-id="browser-switch"
            />
          }
          label={
            browserCondition ? (
              <StyledText style={typographyStyles}>On</StyledText>
            ) : (
              <StyledText style={typographyStyles}>Off</StyledText>
            )
          }
        />
      </Wrapper>
      {browserCondition && (
        <Wrapper>
          <Label style={labelStyles}>Select a Browser To Alert</Label>
          <StyledFormControl
            data-test-id="banner-select-dropdown"
            id={'banner-controlled-select'}
          >
            <StyledDropdown
              type="single"
              value={getBrowserValue()}
              items={browserListItems}
              fieldsetLabel="Browsers"
              disabled
              onChange={onDropDownChange}
              data-test-id="browser-select"
              placeholderText={<StyledText>Please Select</StyledText>}
            />
          </StyledFormControl>
        </Wrapper>
      )}
    </>
  );
};
