import React, { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Config } from './types';
import { Header, NavType, Navigation } from '@uitk/react';
import { useLocation } from 'react-router-dom';
import { NavLink } from '../shared/types';
import { BorderData } from 'builder/components/WidgetConfigurations/Border';

const StyledNavWidget = styled.div<{
  borderData: BorderData;
  lineColor: string;
  textColor: string;
  isDifferentTextColorOnHover: boolean;
  hoverTextColor: string;
}>`
  border-style: solid;
  border-width: ${p => p.borderData?.thickness}px;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  & .uitk-header {
    display: none;
  }
  & ul > li > a:hover,
  & ul > li > button:hover,
  & ul > li > a:focus,
  & ul > li > button:focus {
    box-shadow: none;
    background-color: transparent;
    color: ${p =>
      p.isDifferentTextColorOnHover ? p.hoverTextColor : p.textColor};
    outline: 3px solid
      ${p => (p.isDifferentTextColorOnHover ? p.hoverTextColor : p.textColor)};
  }
`;

const StyledVerticalNavigation = styled(Navigation)<{
  background: string;
  textColor: string;
  fontSize: number;
  font: string;
  showDividerLines: boolean;
  dividerLineColor: string;
  iconColor: string;
  width: number;
  isDifferentTextColorOnHover: boolean;
  hoverTextColor: string;
}>`
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding-top: 0;
  background: ${p => p.background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  & ul,
  & ul > li > ul {
    border-style: none;
  }
  & a[data-testid^='nav-link'] {
    font-size: ${p => p.fontSize}pt;
    color: ${p => p.textColor};
    font-family: ${p => p.font};
    border: none;
    margin: 0.25rem;
    border-radius: 3px;
  }
  & a[data-testid^='nav-link']:hover {
    font-size: ${p => p.fontSize}pt;
    color: ${p =>
      p.isDifferentTextColorOnHover ? p.hoverTextColor : p.textColor};
    font-family: ${p => p.font};
    border: none;
    margin: 0.25rem;
    border-radius: 3px;
  }
  & button[data-testid^='nav-button'] {
    font-size: ${p => p.fontSize}pt;
    color: ${p => p.textColor};
    font-family: ${p => p.font};
    border: none;
    margin: 0.25rem;
    border-radius: 3px;
    width: auto;
  }
  & button[data-testid^='nav-button']:hover {
    font-size: ${p => p.fontSize}pt;
    color: ${p =>
      p.isDifferentTextColorOnHover ? p.hoverTextColor : p.textColor};
    font-family: ${p => p.font};
    border: none;
    margin: 0.25rem;
    border-radius: 3px;
    width: auto;
  }
  & > ul > li > a:before,
  & > ul > li > button:before {
    height: ${p => (p.showDividerLines ? '1px' : '0px')};
    background-color: ${p => p.dividerLineColor};
  }
  & svg[class^='icon '] {
    fill: ${p => p.iconColor};
  }
  & a[data-testid^='nav-link'] > span > span > img,
  & button[data-testid^='nav-button'] > span > span > img {
    width: ${p => p.fontSize * 1.75}px;
  }
`;

const NavContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 980px) {
    flex-direction: row;
  }
`;

const Main = styled.main`
  margin: 20px;
`;

const Divider = styled.div`
  width: 1px; // or any height you want for the divider
  background-color: #000; // or any color you want for the divider
  height: 100%;
`;

const StyledDiv = styled.div<{
  background: any;
  textColor: string;
  fontSize: number;
  font: string;
  showDividerLines: boolean;
  dividerLineColor: string;
  iconColor: string;
  fullScreen: boolean;
  height: number;
}>`
  max-width: 1280px;
  margin: 0 auto;
  & button[data-testid^='nav-button'],
  & a[data-testid^='nav-link'] {
    font-size: ${p => p.fontSize}pt;
    color: ${p => p.textColor};
    font-family: ${p => p.font};
    border: 2px solid transparent;
  }
  & a[data-testid^='nav-link']:hover {
    border: 2px solid #f4f4f4;
  }
  & button[data-testid^='nav-button']:hover {
    border: 2px solid #f4f4f4;
  }
  & svg[class^='icon '] {
    fill: ${p => p.iconColor};
  }
  & a[data-testid^='nav-link']:before,
  & button[data-testid^='nav-button']:before {
    height: ${p => (p.showDividerLines ? '1px' : '0px')};
    background-color: ${p => p.dividerLineColor};
  }
  & a[data-testid^='nav-link']:hover:before,
  & button[data-testid^='nav-button']:hover:before {
    border: none;
  }
  & > div + div > div > nav {
    padding-left: 0;
    margin-left: ${p => (p.fullScreen ? '2.5rem' : 0)};
    box-shadow: none;
  }
  & > div + div > div > nav > ul,
  & > div + div > div > nav > ul > li > button + ul {
    background: ${p => p.background};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: content-box;
  }
  & > div + div > div > nav > ul > li > button + ul {
    top: ${p => p.height}px;
  }
  & a[data-testid^='nav-link'] > span > span > img,
  & button[data-testid^='nav-button'] > span > span > img {
    width: ${p => p.fontSize * 1.75}px;
  }
`;

const StyledHorizontalNavigation = styled(Header)`
  & + div {
    height: 70px;
  }
`;

export function transformChildren(
  items: NavLink[],
  parentId,
  childOrder
): any[] {
  const sortedArray = [...items].filter(
    item => item.parentSectionId === parentId && item.visible
  );
  if (sortedArray.length > 1) {
    sortedArray.sort((a, b) => {
      const positionA = childOrder.indexOf(a.id);
      const positionB = childOrder.indexOf(b.id);
      const assignedPositionA =
        positionA === -1 ? Number.MAX_SAFE_INTEGER : positionA;
      const assignedPositionB =
        positionB === -1 ? Number.MAX_SAFE_INTEGER : positionB;
      return assignedPositionA - assignedPositionB;
    });
  }
  return sortedArray.map(item => {
    const { displayText: label, url, icon } = item;
    const customContent =
      item.icon.url !== '' ? <img src={icon.url} alt={icon.alt} /> : '';
    return { label, url, customContent };
  });
}

export function transformToNavigationConfig(
  items: NavLink[],
  treeOrder
): any[] {
  const sortedArray = [...items]
    .filter(item => item.visible)
    .sort((a, b) => {
      const positionA = treeOrder.indexOf(a.id);
      const positionB = treeOrder.indexOf(b.id);
      const assignedPositionA =
        positionA === -1 ? Number.MAX_SAFE_INTEGER : positionA;
      const assignedPositionB =
        positionB === -1 ? Number.MAX_SAFE_INTEGER : positionB;
      return assignedPositionA - assignedPositionB;
    });
  return sortedArray
    .filter(
      item =>
        item.parentSectionId === undefined || item.parentSectionId === null
    )
    .map(item => {
      const { displayText: label, url, isSection, icon } = item;
      const customContent =
        item.icon.url !== '' ? <img src={icon.url} alt={icon.alt} /> : '';
      if (isSection) {
        const childrenLinks = items.filter(item => item.parentSectionId);
        return {
          label,
          customContent,
          links: transformChildren(childrenLinks, item.id, item.childOrder),
        };
      } else {
        return { label, url, customContent };
      }
    });
}

export function background(backgroundData, backgroundImage) {
  if (backgroundData.style === 'Color') {
    return backgroundData?.color;
  }
  if (backgroundData.style === 'Image') {
    return `url('${backgroundImage?.url}')`;
  }
  return 'transparent';
}

const NavWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { editing } = props;
  const {
    width,
    height,
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    font,
    fontSize,
    textColor,
    showDividerLines,
    dividerLineColor,
    treeOrder,
    iconColor,
    paddingData,
    menuItems,
    style,
    enableFullScreen,
    isDifferentTextColorOnHover,
    hoverTextColor,
  } = props.widgetState.config;

  const useCurrentRoute = () => {
    const { pathname: route } = useLocation();
    return route;
  };

  const [activeLink, setActiveLink] = useState(
    props.widgetState.config.menuItems[0].id
  );

  const navigationLinks = transformToNavigationConfig(menuItems, treeOrder);

  const visibleChange = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const activeLinkTitle = e.currentTarget.innerText;
      const currentActiveLink = menuItems.find(
        item => item.displayText === activeLinkTitle
      );
      props.changeWidgetVisible(
        props.siteId,
        props.currentPage.pageId,
        props.widgetState.id,
        `section_menu_${currentActiveLink.id}`,
        true
      );
      setActiveLink(currentActiveLink.id);
    },
    [activeLink]
  );

  const navigationConfig = {
    links: navigationLinks,
    onLinkClick: (e: React.MouseEvent<HTMLElement>) => {
      if (editing || style === 'Vertical') {
        e.preventDefault();
        visibleChange(e);
      }
    },
  };

  return (
    <StyledNavWidget
      data-test-id="nav-widget"
      borderData={borderData}
      lineColor={lineColor}
      textColor={textColor}
      isDifferentTextColorOnHover={isDifferentTextColorOnHover}
      hoverTextColor={hoverTextColor}
      id={'NavWidget'}
      className={'NavWidget'}
      style={{
        height: '100%',
        width: '100%',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        background:
          style === 'Horizontal'
            ? background(backgroundData, backgroundImage)
            : 'none',
      }}
    >
      {style === 'Vertical' && (
        <NavContent>
          <StyledVerticalNavigation
            data-test-id="vertical-navigation"
            font={font}
            fontSize={fontSize}
            textColor={textColor}
            iconColor={iconColor}
            showDividerLines={showDividerLines}
            dividerLineColor={dividerLineColor}
            background={background(backgroundData, backgroundImage)}
            useLocation={useCurrentRoute}
            variant={NavType.VERTICAL}
            config={navigationConfig}
            width={width}
            isDifferentTextColorOnHover={isDifferentTextColorOnHover}
            hoverTextColor={hoverTextColor ? hoverTextColor : 'black'}
          />
          <Divider />
          <Main tabIndex={-1} id="main"></Main>
        </NavContent>
      )}
      {style === 'Horizontal' && (
        <StyledDiv
          data-test-id="horizontal-navigation"
          font={font}
          fontSize={fontSize}
          textColor={textColor}
          iconColor={iconColor}
          showDividerLines={showDividerLines}
          dividerLineColor={dividerLineColor}
          background={background(backgroundData, backgroundImage)}
          fullScreen={enableFullScreen}
          height={height}
        >
          <StyledHorizontalNavigation
            data-test-id="styled-horizontal-navigation"
            logoContent={null}
            horizontalNavigation={navigationConfig}
            useLocation={useCurrentRoute}
            productName=""
            productLink=""
          />
        </StyledDiv>
      )}
    </StyledNavWidget>
  );
};
export default NavWidget;
