import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import {
  Panel,
  Label,
  TextInput,
  FormControl,
  Select,
  Checkbox,
} from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  PanelTop,
  StyledPanel,
  StyledPanelTitle,
} from '../../Text/TextWidgetControl';
import {
  FieldWrapper,
  OptionalLabel,
  StyledLabel,
  StyledTextInput,
} from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { openConditionalEmailDialog } from 'shared/state/ducks/ui';
import ConditionalEmailDialog from '../ConditionalEmail/ConditionalEmailDialog';

const StyledDiv = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0.25rem 0;
  align-items: center;
`;
const StyledFormDiv = styled.div`
  width: 100%;
  display: inline-flex;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-top: -0.15rem;
`;
const LabelWrapper = styled.div`
  display: inline-grid;
  align-items: baseline;
`;
const TimeUnitSelect = styled(Select)`
  font-family: 'OptumSans';
  width: 50%;
  border-radius: 0.375rem;
  .kASQuK {
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;
const StyledTimeInput = styled(TextInput)`
  height: 2.5rem;
  margin-top: 0.25rem;
  font-family: 'OptumSans';
`;
const StyledFormControl = styled(FormControl)`
  width: 50%;
  padding-right: 0.5rem;
`;
const StyledCheckboxControl = styled(FormControl)`
  width: auto;
  height: auto;
`;
const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 1em;
  .MuiButton-label {
    font-family: 'OptumSans';
    font-weight: 700;
    text-transform: none;
    font-size: 0.8rem;
  }
`;

const FormWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const [email, setEmail] = useState(widgetState.config.emailTo);
  const [formName, setFormName] = useState(widgetState.config.formName);
  const [subject, setSubject] = useState(widgetState.config.subject);
  const [enableConditionalEmail, setEnableConditionalEmail] = useState(
    widgetState.config.conditionalEmailEnabled
  );
  const [timeFrameValue, setTimeFrameValue] = useState(
    widgetState.config.timeFrameValue
  );
  const [timeFrameUnit, setTimeFrameUnit] = useState(
    widgetState.config.timeFrameUnit
  );

  const dispatch = useDispatch();
  const error =
    !/^[1-9][0-9]?$|^100$/.test(timeFrameValue) && timeFrameValue?.length > 0
      ? 'Must be a number between 1-100'
      : '';

  function eligibleFormWidgets() {
    let eligibleWidgets = [];
    const widgets = props.widgetState.children;
    widgets.forEach(child => {
      if (
        props.currentPage.content[child].type === 'Checkbox' ||
        props.currentPage.content[child].type === 'Radio' ||
        props.currentPage.content[child].type === 'Dropdown'
      ) {
        eligibleWidgets.push(props.currentPage.content[child]);
      }
      return eligibleWidgets;
    });
    return eligibleWidgets;
  }
  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleFormName = (e: ChangeEvent<HTMLInputElement>) => {
    setFormName(e.target.value);
    onChange({
      ...value,
      formName: e.target.value,
    });
  };

  const handleValues = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    onChange({
      ...value,
      emailTo: e.target.value,
    });
  };
  const handleSubject = (e: ChangeEvent<HTMLInputElement>) => {
    setSubject(e.currentTarget.value);
    onChange({
      ...value,
      subject: e.currentTarget.value,
    });
  };
  const handleTimeFrameValue = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeFrameValue(e.currentTarget.value);
    onChange({
      ...value,
      timeFrameValue: e.currentTarget.value,
    });
  };
  const handleTimeFrameUnit = (e: ChangeEvent<HTMLSelectElement>) => {
    setTimeFrameUnit(e.target.value);
    onChange({
      ...value,
      timeFrameUnit: e.target.value,
    });
  };
  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };
  const handleConditionalEmailCheck = e => {
    setEnableConditionalEmail(e.target.checked);
    onChange({
      ...value,
      conditionalEmailEnabled: e.target.checked,
    });
  };
  const handleEditConditionalEmailClick = () => {
    dispatch(openConditionalEmailDialog());
  };
  const renderEditConditionalLink = () => {
    const linkTitle =
      !widgetState.config.conditionalEmailConfig ||
      widgetState.config.conditionalEmailConfig.length === 0
        ? 'Add Customized E-mail List'
        : 'Edit Customized E-mail List';
    return (
      <>
        <StyledButton
          onClick={handleEditConditionalEmailClick}
          variant="contained"
        >
          {linkTitle}
        </StyledButton>
      </>
    );
  };
  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-panel"
          >
            <PanelTop>
              <FieldWrapper>
                <LabelWrapper>
                  <StyledLabel>Form Name</StyledLabel>
                  <OptionalLabel>
                    (This name will be displayed in the form submission center)
                  </OptionalLabel>
                </LabelWrapper>
                <StyledTextInput
                  data-test-id="form-name"
                  value={formName}
                  onChange={handleFormName}
                />
                <LabelWrapper>
                  <StyledLabel>Form Recipients</StyledLabel>
                  <OptionalLabel>
                    (Add comma-separated e-mail addresses)
                  </OptionalLabel>
                </LabelWrapper>
                <StyledTextInput
                  data-test-id="email-input"
                  value={email}
                  onChange={handleValues}
                />
                <StyledDiv>
                  <LabelWrapper>
                    <StyledLabel>Enable conditional e-mail?</StyledLabel>
                  </LabelWrapper>
                  <StyledCheckboxControl id="checkbox-controlled">
                    <StyledCheckbox
                      data-test-id="browser-checkbox"
                      checked={enableConditionalEmail}
                      onChange={handleConditionalEmailCheck}
                    />
                  </StyledCheckboxControl>
                </StyledDiv>
                {enableConditionalEmail && renderEditConditionalLink()}
                <ConditionalEmailDialog
                  formWidget={props.widgetState}
                  eligibleFormWidgets={eligibleFormWidgets()}
                  conditionalEmailConfig={
                    widgetState.config.conditionalEmailConfig
                  }
                  siteId={props.site.id}
                  currentPage={props.currentPage}
                />
                <LabelWrapper>
                  <StyledLabel>Subject Line</StyledLabel>
                  <OptionalLabel>
                    (Add content for e-mail subject line)
                  </OptionalLabel>
                </LabelWrapper>
                <StyledTextInput
                  data-test-id="subject-input"
                  value={subject}
                  onChange={handleSubject}
                />
                <LabelWrapper>
                  <StyledLabel>Response Time Frame</StyledLabel>
                  <OptionalLabel>
                    (Timeline given to your end users in which your team will
                    respond to their form submission)
                  </OptionalLabel>
                </LabelWrapper>
                <StyledFormDiv>
                  <StyledFormControl id="pattern-text-input" error={error}>
                    <StyledTimeInput
                      place
                      data-test-id="timeframe-input"
                      value={timeFrameValue}
                      onChange={handleTimeFrameValue}
                    />
                  </StyledFormControl>
                  <TimeUnitSelect onChange={handleTimeFrameUnit}>
                    <option value="" selected={timeFrameUnit === ''}></option>
                    <option
                      value="hour(s)"
                      selected={timeFrameUnit === 'hour(s)'}
                    >
                      hour(s)
                    </option>
                    <option
                      value="day(s)"
                      selected={timeFrameUnit === 'day(s)'}
                    >
                      day(s)
                    </option>
                    <option
                      value="week(s)"
                      selected={timeFrameUnit === 'week(s)'}
                    >
                      week(s)
                    </option>
                  </TimeUnitSelect>
                </StyledFormDiv>
                <StyledLabel>Response Preview</StyledLabel>
                <div>
                  "You can expect a response from our team within{' '}
                  {timeFrameValue} {timeFrameUnit}."
                </div>
              </FieldWrapper>
            </PanelTop>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="background-panel"
          >
            <Background
              data-test-id="background-component"
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              handleBackgroundColor={handleBackgroundColor}
              widgetState={widgetState}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              activeWidgetId={props.widgetState.id}
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default FormWidgetControl;
