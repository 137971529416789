import React, { useState, useCallback } from 'react';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
} from 'date-fns';
import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

interface CalendarContainerProps {
  width: number;
  height: number;
  fontFamily: string;
  accentColor: string;
}

const CalendarContainer = styled.div<CalendarContainerProps>`
  display: flex;
  flex-direction: column;
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ accentColor }) => accentColor};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: 20px;
  box-shadow: 0px 0px 3px 0px rgba(13, 6, 6, 0.4);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
`;

const ArrowContainer = styled.div`
  cursor: pointer;
  padding: 5px;
`;

const DaysRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  font-family: 'OptumSans';
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  flex-grow: 1;
`;

interface CellProps {
  isDisabled: boolean;
  isSelected: boolean;
  isCurrentDay: boolean;
  fontFamily: string;
}

const Cell = styled.div<CellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  font-family: ${({ fontFamily }) => fontFamily || 'OptumSans'};
  background-color: ${({ isSelected, isCurrentDay }) =>
    isSelected || isCurrentDay ? '#007bff' : 'inherit'};
  color: ${({ isSelected, isCurrentDay }) =>
    isSelected || isCurrentDay ? 'white' : 'inherit'};
  border-radius: ${({ isSelected, isCurrentDay }) =>
    isSelected || isCurrentDay ? '50%' : '0'};
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: #ccc;
    background-color: #f0f0f0;
  `}
`;

const Number = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DateContainer = styled.div`
  text-align: center;
`;

interface MonthYearProps {
  accentColor: string;
  fontFamily: string;
  isMonth?: boolean;
}

const MonthYear = styled.div<MonthYearProps>`
  color: ${({ accentColor }) => accentColor || '#052575'};
  font-family: ${({ fontFamily }) => fontFamily || 'OptumSans'};
  font-size: ${({ isMonth }) => (isMonth ? '24px' : '16px')};
`;

interface CalendarProps {
  width?: number;
  height?: number;
  fontFamily?: string;
  accentColor?: string;
}

const Calendar: React.FC<CalendarProps> = ({
  width = 300,
  height = 400,
  fontFamily = 'OptumSans',
  accentColor = '#007bff',
}) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleMonthChange = useCallback((direction: 'prev' | 'next') => {
    setCurrentMonth(prevMonth =>
      direction === 'prev' ? subMonths(prevMonth, 1) : addMonths(prevMonth, 1)
    );
  }, []);

  const renderHeader = useCallback(
    () => (
      <Header>
        <ArrowContainer onClick={() => handleMonthChange('prev')}>
          <ArrowLeftIcon />
        </ArrowContainer>
        <DateContainer>
          <MonthYear accentColor={accentColor} fontFamily={fontFamily} isMonth>
            {format(currentMonth, 'MMMM')}
          </MonthYear>
          <MonthYear accentColor={accentColor} fontFamily={fontFamily}>
            {format(currentMonth, 'yyyy')}
          </MonthYear>
        </DateContainer>
        <ArrowContainer onClick={() => handleMonthChange('next')}>
          <ArrowRightIcon />
        </ArrowContainer>
      </Header>
    ),
    [currentMonth, accentColor, fontFamily, handleMonthChange]
  );

  const renderDays = useCallback(() => {
    const days = [];
    const date = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Cell
          key={i}
          isDisabled={false}
          isSelected={false}
          isCurrentDay={false}
          fontFamily={fontFamily}
        >
          {format(addDays(date, i), 'EEE')}
        </Cell>
      );
    }

    return <DaysRow>{days}</DaysRow>;
  }, [currentMonth, fontFamily]);

  const renderCells = useCallback(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        days.push(
          <Cell
            key={day.toString()}
            isDisabled={!isSameMonth(day, monthStart)}
            isSelected={isSameDay(day, selectedDate)}
            isCurrentDay={isSameDay(day, new Date())}
            onClick={() => setSelectedDate(cloneDay)}
            fontFamily={fontFamily}
          >
            <Number>{format(day, 'd')}</Number>
          </Cell>
        );
        day = addDays(day, 1);
      }
      rows.push(<Row key={day.toString()}>{days}</Row>);
      days = [];
    }

    return <Body>{rows}</Body>;
  }, [currentMonth, selectedDate, fontFamily]);

  return (
    <CalendarContainer
      width={width}
      height={height}
      accentColor={accentColor}
      fontFamily={fontFamily}
    >
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </CalendarContainer>
  );
};

export default React.memo(Calendar);
