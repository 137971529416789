import { PureComponent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { defaultPageContent } from 'builder/util/constants';

const StyledDialog = styled(Dialog)`
  & h2[class='MuiTypography-root MuiTypography-h6'] {
    font-family: OptumSans;
    font-weight: 700;
  }
  & p[class='MuiTypography-root MuiTypography-body1'] {
    font-family: OptumSans;
    font-weight: 400;
  }
  &
    button[class='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSecondary'] {
    font-family: OptumSans;
    font-weight: 700;
  }
  & button[class='MuiButtonBase-root MuiButton-root MuiButton-text'] {
    font-family: OptumSans;
    font-weight: 700;
  }
`;

export enum SiteListDialogType {
  UNPUBLISH = 'UNPUBLISH',
  DELETE = 'DELETE',
  CANNOT_DELETE = 'CANNOT_DELETE',
  CREATE_PAGE = 'CREATE_PAGE',
}

export interface SiteListDialogProps {
  open?: boolean;
  dialogType?: string;
  siteName?: string;
  siteId?: number;
  openSiteListDialog?: () => void;
  closeSiteListDialog?: () => void;
  archiveSite?: (siteId: number) => void;
  unPublishSite?: (siteId: number) => Promise<void>;
  fetchSites: () => void;
  fetchSitesByUuid: () => void;
  createPage: (siteId: number, page: Partial<PageVersion>) => Promise<Page>;
  routeToHomePage?: (siteId: number, pageId: number) => void;
}

class SiteListDialogData {
  title: string;
  body: string;
  cancelText: string;
  confirmText: string;
  confirm: () => void;
  cancel: () => void;
}

class SiteListDialog extends PureComponent<SiteListDialogProps> {
  handleUnPublishConfirmed = () => {
    const {
      unPublishSite,
      siteId,
      closeSiteListDialog,
      fetchSitesByUuid,
    } = this.props;
    unPublishSite(siteId).then(() => {
      fetchSitesByUuid();
    });
    closeSiteListDialog();
  };

  handleUnPublishCancelled = () => {
    const { closeSiteListDialog } = this.props;
    closeSiteListDialog();
  };

  handleDeleteCancelled = () => {
    const { closeSiteListDialog } = this.props;
    closeSiteListDialog();
  };

  handleDeleteConfirm = () => {
    const { archiveSite, siteId, closeSiteListDialog } = this.props;
    archiveSite(siteId);
    closeSiteListDialog();
  };

  handleCannotDeleteConfirmed = () => {
    const { closeSiteListDialog } = this.props;
    closeSiteListDialog();
  };

  handleHomePageCancelled = () => {
    const { closeSiteListDialog } = this.props;
    closeSiteListDialog();
  };

  handleCreateDefaultHomePage = () => {
    const {
      createPage,
      routeToHomePage,
      closeSiteListDialog,
      siteId,
    } = this.props;

    const payload = {
      slug: 'home',
      title: 'Home',
      authentication: false,
      headerEnabled: false,
      footerEnabled: false,
      content: defaultPageContent,
    };
    createPage(siteId, payload).then(page => {
      routeToHomePage(siteId, page.id);
      closeSiteListDialog();
    });
  };

  setupDialog = (dialogType: string, siteName: string): SiteListDialogData => {
    switch (dialogType) {
      case SiteListDialogType.DELETE: {
        return {
          title: 'Confirm site delete',
          body: `You are about to delete the ${siteName} site. This is an irreversible, permanent action and all site contents will be lost with no option to republish later.
                Do you want to continue?`,
          cancelText: 'cancel',
          confirmText: 'delete',
          cancel: this.handleDeleteCancelled,
          confirm: this.handleDeleteConfirm,
        };
      }
      case SiteListDialogType.UNPUBLISH: {
        return {
          title: 'Confirm site unpublish',
          body: `You are about to unpublish the ${siteName} site. This will make the site unreachable to all users.
                However, the site contents will be available here for
                republishing the site later. Do you want to continue?`,
          cancelText: 'cancel',
          confirmText: 'unpublish',
          cancel: this.handleUnPublishCancelled,
          confirm: this.handleUnPublishConfirmed,
        };
      }
      case SiteListDialogType.CANNOT_DELETE: {
        return {
          title: 'Cannot delete site',
          body: `${siteName} site cannot be deleted because there are applications associated with this site. 
            To delete the site, first delete all associated applications in AppManager.`,
          cancelText: 'cancel',
          confirmText: 'ok',
          cancel: null,
          confirm: this.handleCannotDeleteConfirmed,
        };
      }
      case SiteListDialogType.CREATE_PAGE: {
        return {
          title: 'Confirm home page creation',
          body: `You are about to create default home page for this site. This will be the first page that visitors see when they visit your site. Are you sure you want to create this page?`,
          cancelText: 'cancel',
          confirmText: 'Create home page',
          cancel: this.handleHomePageCancelled,
          confirm: this.handleCreateDefaultHomePage,
        };
      }
      default: {
        return {
          title: '',
          body: ``,
          cancelText: '',
          confirmText: '',
          cancel: null,
          confirm: null,
        };
      }
    }
  };

  render() {
    const { open, dialogType, siteName } = this.props;
    const data = this.setupDialog(dialogType, siteName) as SiteListDialogData;

    return (
      <StyledDialog
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">{data.title}</DialogTitle>
        <DialogContent>
          <div>{<Typography variant="body1">{data.body}</Typography>}</div>
        </DialogContent>
        <DialogActions>
          {data.cancel !== null && (
            <Button onClick={data.cancel}>{data.cancelText}</Button>
          )}
          <Button onClick={data.confirm} color="secondary">
            {data.confirmText}
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}
export default SiteListDialog;
