import { Dispatch } from 'shared/state';
import { connect } from 'react-redux';
import NavWidget from './NavWidget';
import { changeWidgetVisible } from 'shared/state/ducks/sites';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeWidgetVisible: (
    siteId: number,
    pageId: number,
    parentWidgetId: string,
    widgetId: string,
    visible: boolean
  ) => {
    dispatch(
      changeWidgetVisible(siteId, pageId, parentWidgetId, widgetId, visible)
    );
  },
});

export default connect(null, mapDispatchToProps)(NavWidget);
