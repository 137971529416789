/*
The SiteLandingPage.tsx file is a React component that serves as the main landing page for a site. It uses TypeScript for static type checking.
The file starts by defining two TypeScript interfaces: TypographyData and SitelandingPageProps. TypographyData describes the properties of a typography object, while SitelandingPageProps describes the properties that the SiteLandingPage component expects to receive.
The SiteLandingPage component uses several hooks from React, such as useState and useEffect, to manage state and side effects. It also uses styled-components for CSS-in-JS styling.
The file contains several styled components, such as Logo, App, AppContent, MainWrapper, Main, EditingContainer, StyledText, StyledSubMenuItem, CustomGridLines, StyledButton, and StyledDialog.
The SiteLandingPage component receives a large number of props, including functions for updating the site, saving the site, adding a child, updating widget position, updating config, setting active widget ID, updating the current page, toggling grid display, saving site pages, creating a page, removing a page, saving a page, initiating logout, and more.
The component also defines several helper functions, such as getMaxYLocation and containerHeight, which are used to calculate the maximum Y location and container height, respectively.
The SiteLandingPage component uses these props and helper functions to render a complex UI with a sidebar, menu, panels, widgets, and more. It also handles user interactions such as submitting the site, setting site data, and saving the site.
*/

import { FC, useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import styled from 'styled-components';
import { PagesPanel } from './PagesPanel/PagesPanel';
import { SiteStylesPanel } from './SiteStylesPanel/SiteStylesPanel';
import { SettingsPanel } from './SettingsPanel/SettingsPanel';
import { WidgetsPanel } from './WidgetsPanel/WidgetsPanel';
import BuilderTopMenu from 'builder/components/BuilderTopMenu';
import { defaultTheme as theme } from 'link-ui-react';
import { IDP } from 'shared/api/idx';
import { NO_IDP, widgetTypes } from 'builder/util/constants';
import './WidgetStyles.css';
import GridLines from 'react-gridlines';
import { Button, Dialog, Text } from '@uitk/react';
import WidgetControlWrapperContainerNew from './WidgetControlWrapperNew';
import WidgetConnectorContainerNew from './WidgetConnector';
import { SiteSlice } from 'shared/state/ducks/site';
import userManager from 'builder/util/userManager';
import { History } from 'history';
import {
  updateFormWidget as doUpdateFormWidget,
  createFormWidget as doCreateFormWidget,
} from 'shared/api/sites';
import { searchWidgetsByType } from 'shared/widgets';

const Logo = styled.img`
  height: 38px;
  width: 60px;
  max-width: 60px;
`;

const logo = (
  <Logo
    src={
      'https://cdn-stage.linkhealth.com/site-builder/horizon/HorizonLogo.png'
    }
    alt="optum-horizon-logo"
  />
);

const backToSites = (
  <img
    // @ts-ignore
    class="fa-regular fa-right-to-bracket fa-rotate-180"
    alt="Back to all Sites"
  ></img>
);

const logout = (
  <img
    //@ts-ignore
    class="fa-regular fa-person-to-door"
    style={{ color: '#d00606' }}
    alt="Sign out of Horizon"
  ></img>
);

const App = styled.div`
  box-sizing: border-box;
  @media only screen and (min-width: 980px) {
    display: flex;
    flex-direction: column;
  }
`;

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 980px) {
    flex-direction: row;
  }
`;

const MainWrapper = styled.div`
  flex-direction: row;
  width: 95%;
  overflow-x: hidden;
`;

const Main = styled.main<{
  scrollHeight: number;
}>`
  font-family: 'OptumSans';
  margin: 20px 0 20px 0;
  flex-direction: row;
  width: 100%;
  height: ${p => p.scrollHeight - 76}px;
`;
const EditingContainer = styled.div`
  height: 100%;
  border: 3px dotted #000;
  position: relative;
  width: 1280px;
  margin: auto;
`;

const StyledText = styled.p`
  color: ${theme.colors.aux.darkNavyBlue};
  font-weight: 700;
  font-size: 15px;
  font-family: 'OptumSans';
  margin-left: 9px;
`;

const StyledSubMenuItem = styled(MenuItem)``;

const CustomGridLines = styled(GridLines)`
  height: 100%;
`;

const StyledButton = styled(Button)`
  font-family: 'OptumSans';
`;

const StyledDialog = styled(Dialog)`
  z-index: 999;
  font-family: 'OptumSans';
  & > div > div > div + h1 {
    font-family: 'OptumSans';
  }
  & > div > div > div > button {
    font-family: 'OptumSans';
  }
`;

export interface TypographyData {
  bold: Boolean;
  italic: Boolean;
  fontSize: string;
  fontFamily: string;
  underLine: Boolean;
  strikeThrough: Boolean;
  leftAlign: Boolean;
  centerAlign: Boolean;
  rightAlign: Boolean;
  lineSpacing: String;
  characterSpacing: string;
  uniqueId?: string;
  textColor: string;
  textHighlightColor: string;
}

export interface SitelandingPageProps<T> {
  widgetState: Widget<T>;
  site: Site;
  pages: Array<Page>;
  page?: Page;
  defaultPage?: Page;
  idps?: Array<IDP>;
  provisioningStores: Array<ProvisioningStore>;
  updateSite: (site: Site) => void;
  saveSite: () => Promise<Site>;
  fetchIdps: () => void;
  fetchProvisioningStores: () => void;
  setResetToFalse: () => void;
  currentPage: PageVersion;
  addChild: (
    type: string,
    initialConfig: T,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => void;
  updateWidgetPosition: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
  onUpdateConfig: (config: any) => void;
  setActiveWidgetId: (widgetId: string) => void;
  activeWidgetId: string;
  updateCurrentPage: (pageVersion: PageVersion) => void;
  updatePageDefaultWidget: (
    widgetId: string,
    config: any,
    xDifference: number,
    yDifference: number
  ) => void;
  toggleShowGrid: () => void;
  shouldDisplayGridLines: boolean;
  onFlush?: () => void;
  saveSitePages?: (site: Site, pages: Array<Page>) => void;
  hasChanged?: boolean;
  swapResetData?: () => void;
  resetUserSyncChange?: () => void;
  userModifiedDataState?: SiteSlice;
  createPage: (page: Partial<PageVersion>) => void;
  removePage: (pageId: number) => void;
  savePage: (pageId: number, page: PageVersion) => void;
  initiateLogout: () => void;
  history: History;
  isAccordionPanelOpen?: boolean;
  accordionHeightShift?: number;
  openSiteListDialog?: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => void;
}
export const getMaxYLocation = (currentPage: PageVersion): number => {
  let maxY = 0,
    yLocation;
  const rootWidgetIds = currentPage.content.root.children;
  let bannerWidgetId;
  rootWidgetIds.forEach(id => {
    if (currentPage.content[id]?.type === widgetTypes.Banner) {
      bannerWidgetId = id;
    }
  });
  Object.keys(currentPage.content).map(item => {
    if (
      currentPage.content[item].config.yLocation === undefined ||
      currentPage.content[item].config.height === undefined
    ) {
      yLocation = 900;
    } else {
      yLocation =
        currentPage.content[item].config.yLocation +
        currentPage.content[item].config.height +
        90;
    }

    if (currentPage.content[item].type === widgetTypes.Application) {
      if (
        currentPage.content['footerSection']?.config.yLocation <
        currentPage.content[item].config.height
      ) {
        currentPage.content['footerSection'].config.yLocation =
          currentPage.content['footerSection']?.config.yLocation +
          (currentPage.content[item]?.config.height -
            currentPage.content['footerSection']?.config.yLocation) +
          20;
      }
    }
    if (bannerWidgetId) {
      yLocation = yLocation + currentPage.content[bannerWidgetId].config.height;
    }

    if (yLocation > maxY) maxY = yLocation;
  });

  return maxY;
};

export const containerHeight = (
  currentPage: PageVersion,
  isAccordionPanelOpen: boolean,
  accordionHeightShift: number
) => {
  if (currentPage) {
    if (Object.keys(currentPage?.content).length <= 1) {
      return 900;
    }
  }

  const maxHeight = getMaxYLocation(currentPage);
  if (isAccordionPanelOpen) {
    return maxHeight + accordionHeightShift;
  }
  return maxHeight;
};
export const SiteLandingPage: FC<SitelandingPageProps<any>> = (
  props: SitelandingPageProps<any>
) => {
  const {
    site,
    pages,
    page,
    idps,
    provisioningStores,
    currentPage,
    updateSite,
    saveSite,
    addChild,
    updateWidgetPosition,
    widgetState,
    onUpdateConfig,
    updateCurrentPage,
    toggleShowGrid,
    shouldDisplayGridLines,
    setResetToFalse,
    saveSitePages,
    hasChanged,
    resetUserSyncChange,
    savePage,
    userModifiedDataState,
    createPage,
    removePage,
    defaultPage,
    updatePageDefaultWidget,
    initiateLogout,
    history,
    isAccordionPanelOpen,
    accordionHeightShift,
    openSiteListDialog,
  } = props;

  const removeChildrenShim = (site: Site) => {
    site.header.nav.items.forEach(item => {
      if (item.type !== 'section' && item.children !== undefined) {
        item.children = undefined;
      }
      if (item.children !== undefined) {
        item.children.forEach(childItem => {
          if (
            childItem.type !== 'section' &&
            childItem.children !== undefined
          ) {
            childItem.children = undefined;
          }
        });
      }
    });
  };

  const handleSubmit = (site: Site) => {
    removeChildrenShim(site);
    const { updateSite, saveSite } = props;
    updateSite(site);
    saveSite();
  };

  const [_siteData, setSiteData] = useState(site);
  const handleSiteData = data => {
    setSiteData(data);
  };

  const [location, setLocation] = useState(undefined);
  const [currentPageId, setCurrentPageId] = useState('');
  const [isNewPage, setIsNewPage] = useState(false);

  useEffect(() => {
    saveSitePages(site, pages);
  }, []);

  useEffect(() => {
    const { config } = props.widgetState;
    onUpdateConfig({ ...config, ...location });
  }, [location]);

  useEffect(() => {
    if (!hasChanged && hasChanged !== undefined) {
      saveSite().then(response => {
        saveSitePages(response, pages);
        resetUserSyncChange();
        pages.forEach(page => {
          handleSavePage(page.id, page.current);
        });
      });
    }
  }, [hasChanged]);

  const getLocationData = loc => {
    setLocation(loc);
  };

  const [openConfigPanel, setOpenConfigPanel] = useState(false);
  const getOpenConfigPanel = (open: boolean) => {
    setOpenConfigPanel(open);
  };

  // prevents ghost image from being sent back to widgets panel after dropping
  const handleDragOver = e => {
    e.preventDefault();
  };
  const [activeWidgetId, setActiveWidgetId] = useState('root');
  const handleActiveWidgetId = (widgetId: string) => {
    setActiveWidgetId(widgetId);
  };

  const userCloseDialogBox = () => {
    resetUserSyncChange();
  };

  const userSyncDismissChange = () => {
    const { swapResetData } = props;
    swapResetData();
    const modifiedSiteMetaData = { ...userModifiedDataState };
    delete modifiedSiteMetaData.pages;
    updateSite(modifiedSiteMetaData);
    updateCurrentPage(
      userModifiedDataState.pages.find(page => page.id == currentPage.pageId)
        .current
    );
  };

  const userSyncSaveChange = () => {
    saveSite().then(response => {
      pages.forEach(page => handleSavePage(page.id, page.current));
      saveSitePages(response, pages);
      resetUserSyncChange();
    });
  };

  const handleFormWidget = (action: string, widgetId: String): Promise<any> => {
    const { site, page } = props;
    const widget = searchWidgetsByType(
      page.current.content,
      'root',
      new Array<Widget<any>>(),
      'Form'
    ).find(widget => widget.id === widgetId);
    if (widget.config.formActionType === 'email') {
      const conditionalEmailList = [
        ...(widget.config.conditionalEmailConfig || []),
      ].map(conditionalEmail => ({
        ...conditionalEmail,
        email: [conditionalEmail.email],
      }));
      switch (action) {
        case 'create':
          return doCreateFormWidget(site.id, page.id, {
            widgetId: widget.id,
            recipientEmails: [widget.config.emailTo],
            enableConditionalEmailTo: widget.config.conditionalEmailEnabled,
            conditionalEmailList: conditionalEmailList,
          });
        case 'update':
          return doUpdateFormWidget(site.id, page.id, {
            widgetId: widget.id,
            recipientEmails: [widget.config.emailTo],
            enableConditionalEmailTo: widget.config.conditionalEmailEnabled,
            conditionalEmailList: conditionalEmailList,
          });
        case 'delete':
          break;
        default:
          break;
      }
    }
  };

  const handleSavePage = (pageId: number, pageVersion: PageVersion) => {
    const { forms } = pageVersion;

    forms?.create?.forEach(item => {
      handleFormWidget('create', item);
    });
    forms?.update?.forEach(item => {
      handleFormWidget('update', item);
    });

    savePage(pageId, pageVersion);
  };

  const handleBackToSites = () => {
    history.go(0);
    history.push('/sites');
    history.go(0);
  };

  const handleLogout = () => {
    initiateLogout();
    userManager.signoutRedirect({
      post_logout_redirect_uri: window.location,
    });
  };

  const handleCurrentPageData = pageData => {
    setCurrentPageId(pageData);
  };
  const handleNewPage = newPage => {
    setIsNewPage(newPage);
  };

  return (
    <>
      <App onDragOver={handleDragOver}>
        <AppContent data-test-id="app-content">
          <Sidebar
            style={{
              height: '919px',
              width: '79px',
              position: 'sticky',
              zIndex: 999,
              top: 0,
              backgroundColor: '#FFFFFF',
            }}
            defaultCollapsed
            data-test-id="sticky-sidebar"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '95vh',
                maxHeight: '55rem',
              }}
            >
              <Menu>
                <MenuItem icon={logo} data-test-id="logo-menu-item"></MenuItem>
                <StyledText data-test-id="horizon-label">Horizon</StyledText>
                <WidgetsPanel
                  getLocationData={getLocationData}
                  pages={pages}
                  currentPage={currentPage}
                  updateSite={updateSite}
                  addChild={addChild}
                  widgetState={widgetState}
                  onUpdateConfig={onUpdateConfig}
                  setResetToFalse={setResetToFalse}
                  updateWidgetPosition={updateWidgetPosition}
                  site={site}
                />
                <SiteStylesPanel site={site} updateSite={updateSite} />
                <PagesPanel
                  pages={pages}
                  siteDetails={site}
                  currentPage={currentPage}
                  createPage={createPage}
                  removePage={removePage}
                  savePage={savePage}
                  handleActiveWidgetId={handleActiveWidgetId}
                  defaultPage={defaultPage}
                  handleCurrentPageData={handleCurrentPageData}
                  handleNewPage={handleNewPage}
                  openPopup={getOpenConfigPanel}
                  updateSite={updateSite}
                  saveSite={saveSite}
                />
                <SettingsPanel
                  siteDetails={{
                    ...site,
                    idpFlag: site.idpHint !== NO_IDP ? 'enable' : 'disable',
                    header: {
                      ...site.header,
                      feedback: {
                        ...site.header.feedback,
                        formFields: site.header.feedback?.formFields || [],
                      },
                    },
                  }}
                  pages={pages}
                  idps={idps}
                  siteIdp={site.idpHint}
                  provisioningStores={provisioningStores}
                  onSubmit={handleSubmit}
                  updateSite={updateSite}
                  sendData={handleSiteData}
                />
              </Menu>
              <Menu>
                <StyledSubMenuItem
                  data-test-id="back-to-sites-button"
                  onClick={handleBackToSites}
                  style={{ marginTop: 'auto' }}
                  title="Back to all Sites"
                >
                  {backToSites}
                </StyledSubMenuItem>
                <StyledSubMenuItem
                  data-test-id="logout-button"
                  onClick={handleLogout}
                  style={{ marginTop: '1rem' }}
                  title="Sign out of Horizon"
                >
                  {logout}
                </StyledSubMenuItem>
              </Menu>
            </div>
          </Sidebar>
          <MainWrapper>
            <BuilderTopMenu
              siteId={site.id}
              pageId={currentPage?.pageId}
              toggleShowGrid={toggleShowGrid}
              closeConfigPanel={getOpenConfigPanel}
              isPreviewMode={false}
              currentPageId={currentPageId}
              isNewPage={isNewPage}
              openSiteListDialog={openSiteListDialog}
            />
            <Main
              tabIndex={-1}
              id="main"
              scrollHeight={containerHeight(
                currentPage,
                isAccordionPanelOpen,
                accordionHeightShift
              )}
              style={{
                backgroundColor: currentPage.pageBackground?.color
                  ? currentPage.pageBackground?.color
                  : '#FFFFFF',
              }}
            >
              <EditingContainer
                className="editingContainer"
                id="editingContainerId"
              >
                <CustomGridLines
                  className="grid-area"
                  cellWidth={shouldDisplayGridLines ? 120 : 0}
                  strokeWidth={2}
                  cellWidth2={10}
                >
                  <WidgetConnectorContainerNew
                    siteId={site.id}
                    pageId={currentPage.pageId}
                    widgetId={activeWidgetId}
                    openPopup={getOpenConfigPanel}
                    currentPage={currentPage}
                    getActiveWidgetId={handleActiveWidgetId}
                    updateCurrentPage={updateCurrentPage}
                    updatePageDefaultWidget={updatePageDefaultWidget}
                    closeConfigPanel={getOpenConfigPanel}
                    editing={true}
                    page={page}
                    updateWidgetPosition={updateWidgetPosition}
                    pages={pages}
                    initiateLogout={initiateLogout}
                  />
                </CustomGridLines>
              </EditingContainer>
              {openConfigPanel && (
                <WidgetControlWrapperContainerNew
                  siteId={site.id}
                  pageId={currentPage.pageId}
                  pages={pages}
                  widgetId={activeWidgetId}
                  site={site}
                  updateSite={updateSite}
                  saveSite={saveSite}
                  openPopup={getOpenConfigPanel}
                  addChild={addChild}
                  currentPage={currentPage}
                  updateCurrentPage={updateCurrentPage}
                  updateWidgetPosition={updateWidgetPosition}
                />
              )}
            </Main>
          </MainWrapper>
        </AppContent>
      </App>
      {hasChanged && (
        <StyledDialog
          title="Save Changes"
          titleAs="h1"
          onClose={userCloseDialogBox}
        >
          <Dialog.Body>
            <Text>
              Someone else modified this site while you were editing it.
            </Text>
          </Dialog.Body>
          <Dialog.Actions>
            <StyledButton onPress={userSyncSaveChange}>
              Save your changes
            </StyledButton>
            <StyledButton onPress={userSyncDismissChange} variant="tertiary">
              Discard changes and show new version
            </StyledButton>
          </Dialog.Actions>
        </StyledDialog>
      )}
    </>
  );
};
