import '../WidgetStyles.css';
import React, { useRef, useEffect } from 'react';
import _ from 'lodash';
import { SizeLocationData } from 'builder/components/WidgetSizeLocation';
import { WidgetWrapper } from '../WidgetWrapper';
import { getWidget } from 'shared/widgets';
import { User } from 'oidc-client';
import { widgetTypes } from 'builder/util/constants';

export interface ItemData {
  width: string;
  height: string;
  x: number;
  y: number;
  content: React.ReactElement;
  uniqueId: string;
  widgetType: string;
}
export interface WidgetConnectorNewProps<T> {
  currentPage: PageVersion;
  activeWidgetId: string;
  widgetState: Widget<T>;
  widgetId?: string;
  pages?: Page[];

  // Optional properties
  editing?: boolean;
  openPopup?: (open: boolean) => void;
  updateSizeLocation?: SizeLocationData;
  onUpdateConfig?: (
    config: any,
    widgetId: string,
    defaultWidget: boolean
  ) => void;
  updateCurrentPageWidgetConfig?: (config: any, widgetId: string) => void;
  getActiveWidgetId?: (widgetId: string) => void;
  updateCurrentPage?: (pageVersion: PageVersion) => void;
  updatePageDefaultWidget?: (
    widgetId: string,
    config: any,
    xDifference: number,
    yDifference: number
  ) => void;
  removeWidget?: (widgetId: string) => void;
  removeDefaultWidgets?: (widgetId: string) => void;
  addSibling?: (
    type: string,
    config: T,
    siblingWidgetId: string,
    children: string[],
    newWidgetId: string,
    tabIndex: number
  ) => void;
  closeConfigPanel?: (open: boolean) => void;
  shouldDisplayGridLines?: boolean;
  addTodoState?: (config: any) => void;
  updatePageContent?: (page: any) => void;
  undoState?: any;
  isUndoClick?: boolean;
  isRedoClick?: boolean;
  isResetState?: boolean;
  resetUndoRedo?: () => void;
  setResetToFalse?: () => void;
  page?: Page;
  onFlush?: () => void;
  updateWidgetPosition?: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
  user?: User;
  siteId?: number;
  isAccordionPanelOpen: boolean;
  accordionShiftWidgetId: string;
  accordionHeightShift: number;
  initiateLogout?: () => void;
}
export const WidgetConnectorNew: React.FC<WidgetConnectorNewProps<any>> = (
  props: WidgetConnectorNewProps<any>
) => {
  const {
    currentPage,
    openPopup,
    onUpdateConfig,
    getActiveWidgetId,
    activeWidgetId,
    updateCurrentPage,
    removeWidget,
    addSibling,
    closeConfigPanel,
    shouldDisplayGridLines,
    editing,
    undoState,
    addTodoState,
    page,
    isUndoClick,
    isRedoClick,
    isResetState,
    setResetToFalse,
    onFlush,
    updateSizeLocation,
    pages,
    updatePageDefaultWidget,
    updateCurrentPageWidgetConfig,
    removeDefaultWidgets,
    user,
    initiateLogout,
  } = props;

  const prevPropsRef = useRef<WidgetConnectorNewProps<any>>(props);

  useEffect(() => {
    const prevProps = prevPropsRef.current;

    if (!_.isEqual(page?.current.content, prevProps?.page?.current.content)) {
      if (!isRedoClick && !isUndoClick && !isResetState) {
        addTodoState(page);
      }
      if (prevProps?.page?.id && prevProps.page.id !== page?.id) {
        onFlush();
      }
      prevPropsRef.current = props;
    }
  }, [
    page,
    activeWidgetId,
    isRedoClick,
    isUndoClick,
    isResetState,
    addTodoState,
    updateSizeLocation,
    currentPage,
  ]);

  useEffect(() => {
    if (isRedoClick || isUndoClick) {
      props.updatePageContent(undoState);
      props.resetUndoRedo();
    }
  }, [
    isRedoClick,
    isUndoClick,
    undoState,
    props.updatePageContent,
    props.resetUndoRedo,
  ]);

  const ChildMemo = React.memo(WidgetWrapper);
  const handleUpdateConfig = (
    config: any,
    widgetId: string,
    defaultWidget: boolean
  ) => {
    onUpdateConfig(config, widgetId, defaultWidget);
    setResetToFalse();
  };
  if (!undoState) {
    addTodoState(page);
  }
  const adjustFooter = (item: string) => {
    if (
      currentPage.content['footerSection']?.config.yLocation <
      currentPage.content[item].config.height
    ) {
      currentPage.content['footerSection'].config.yLocation =
        currentPage.content['footerSection']?.config.yLocation +
        (currentPage.content[item]?.config.height -
          currentPage.content['footerSection']?.config.yLocation) +
        360;
      currentPage.content['footerSection']?.children?.forEach(footerChild => {
        if (
          currentPage.content[footerChild].config.yLocation <
          currentPage.content['footerSection'].config.yLocation
        ) {
          currentPage.content[footerChild].config.yLocation =
            currentPage.content['footerSection'].config.yLocation + 25;
        }
      });
      // If the divider is around the footer section, then move it down
      Object.keys(currentPage.content).forEach(widgetId => {
        if (
          currentPage.content[widgetId].type === widgetTypes.Divider &&
          currentPage.content[widgetId].config?.yLocation <=
            currentPage.content['footerSection'].config.yLocation - 5
        ) {
          currentPage.content[widgetId].config.yLocation =
            currentPage.content['footerSection'].config.yLocation - 2;
        }
      });
    }
  };
  return (
    <div className="widgetConnectorNewDiv" id="widgetConnectorNewId">
      {currentPage &&
        Object.keys(currentPage.content).map(item => {
          if (
            currentPage.content[item].config?.isHorizonWidget === true &&
            (currentPage.content[item].config?.visible === true ||
              currentPage.content[item].config?.visible === undefined)
          ) {
            const WidgetComponent = getWidget(
              currentPage.content[item].type,
              true
            ).components.render;
            const renderComponent = (
              <WidgetComponent
                currentPage={currentPage}
                widgetState={currentPage.content[item]}
                onChange={handleUpdateConfig}
                value={currentPage.content[item].config}
                editing={props.editing}
                siteId={props.siteId}
                pages={pages}
                user={user}
                initiateLogout={initiateLogout}
              />
            );
            if (editing) {
              return (
                <ChildMemo
                  widgetId={item}
                  updateWidgetPosition={props.updateWidgetPosition}
                  locationCoords={{
                    x: currentPage.content[item].config.xLocation,
                    y:
                      currentPage.content[item].config.yLocation +
                      (currentPage.content[item].config.marginTop || 0),
                    width: currentPage.content[item].config.width,
                    height: currentPage.content[item].config.height,
                    minWidth: currentPage.content[item].config.minWidth,
                    minHeight: currentPage.content[item].config.minHeight,
                    widgetType: currentPage.content[item].type,
                  }}
                  widgetState={currentPage.content[item]}
                  key={item}
                  openPopup={openPopup}
                  component={renderComponent}
                  onUpdateConfig={onUpdateConfig}
                  updateCurrentPageWidgetConfig={updateCurrentPageWidgetConfig}
                  removeDefaultWidgets={removeDefaultWidgets}
                  getActiveWidgetId={getActiveWidgetId}
                  activeWidgetId={activeWidgetId}
                  currentPage={currentPage}
                  updateCurrentPage={updateCurrentPage}
                  removeWidget={removeWidget}
                  addSibling={addSibling}
                  closeConfigPanel={closeConfigPanel}
                  shouldDisplayGridLines={shouldDisplayGridLines}
                  editingMode={true}
                  setResetToFalse={setResetToFalse}
                  updatePageDefaultWidget={updatePageDefaultWidget}
                  user={user}
                  pages={pages}
                  isAccordionPanelOpen={props.isAccordionPanelOpen}
                  accordionShiftWidgetId={props.accordionShiftWidgetId}
                  accordionHeightShift={props.accordionHeightShift}
                  initiateLogout={initiateLogout}
                  siteId={props.siteId}
                />
              );
            } else {
              if (currentPage.content[item].type === widgetTypes.Application) {
                adjustFooter(item);
              }
              return (
                <ChildMemo
                  widgetId={item}
                  locationCoords={{
                    x: currentPage.content[item].config.xLocation,
                    y:
                      currentPage.content[item].config.yLocation +
                      (currentPage.content[item].config.marginTop || 0),
                    width: currentPage.content[item].config.width,
                    height: currentPage.content[item].config.height,
                    minWidth: currentPage.content[item].config.minWidth,
                    minHeight: currentPage.content[item].config.minHeight,
                    widgetType: currentPage.content[item].type,
                    zIndex: currentPage.content[item].config.zIndex,
                  }}
                  widgetState={currentPage.content[item]}
                  key={item}
                  component={renderComponent}
                  activeWidgetId={activeWidgetId}
                  currentPage={currentPage}
                  shouldDisplayGridLines={shouldDisplayGridLines}
                  editingMode={false}
                  updatePageDefaultWidget={updatePageDefaultWidget}
                  user={user}
                  pages={pages}
                  isAccordionPanelOpen={props.isAccordionPanelOpen}
                  accordionShiftWidgetId={props.accordionShiftWidgetId}
                  accordionHeightShift={props.accordionHeightShift}
                  initiateLogout={initiateLogout}
                  siteId={props.siteId}
                />
              );
            }
          }
        })}
    </div>
  );
};
