import React, { ReactElement } from 'react';
import { Config } from './types';
import styled from 'styled-components';
import Calendar from './Calendar';

const StyledCalendar = styled.div<{
  backgroundData: any;
  backgroundImage: any;
  borderData: any;
  lineColor: string;
}>`
  margin-top: 0;
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  background-color: ${p =>
    p.backgroundData?.color !== '' ? p.backgroundData?.color : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  height: 100%;
  width: 100%;
  box-shadow: none;
`;

const CalendarWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const fontFamily = props.widgetState.config.fontFamily;
  const accentColor = props.widgetState.config.calendarAccentColor;

  console.log(fontFamily, accentColor);

  return (
    <StyledCalendar
      backgroundData={props.widgetState.config.backgroundData}
      backgroundImage={props.widgetState.config.backgroundImage}
      borderData={props.widgetState.config.borderData}
      lineColor={props.widgetState.config.lineColor}
    >
      {/* Calendar content */}
      <Calendar
        width={props.widgetState.config.width}
        height={props.widgetState.config.height}
        accentColor={props.widgetState.config.calendarAccentColor}
        fontFamily={props.widgetState.config.fontFamily}
      />
    </StyledCalendar>
  );
};

export default CalendarWidget;
